export default {
    title: 'Mes FDES',
    back_link: {
        route: 'Home',
        text: "Retour à l'accueil",
    },
    search: {
        placeholder: 'Rechercher',
    },
    table_headers: [
        'ID',
        'Nom du projet',
        'Nom du calcul',
        'Produit',
        'Date de création',
        'Excel',
        'XML',
        'PDF',
        '',
    ],
    exports: [
        {
            type: 'xlsx',
            label: 'Tableur Excel',
            img: 'excel.png',
        },
        {
            type: 'xml',
            label: 'Fichier XML',
            img: 'xml.png',
        },
        {
            type: 'pdf',
            label: 'FDES (PDF)',
            img: 'pdf.png',
        },
    ],
};
