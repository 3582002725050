import jwt_decode from 'jwt-decode';

export function isTokenValid(token) {
  if (!token) return false;

  try {
    const decodedToken = jwt_decode(token);
    const expiration = decodedToken.exp;
    const now = Date.now() / 1000;
    return expiration > now;
  } catch (err) {
    console.log('Invalid token:', err);
    return false;
  }
}