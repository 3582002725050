export default {
  verify_email: {
    text: `<p>Ce lien semble être expiré. Merci de nous contacter.</p>`,
    button: "Retourner à la page d'accueil",
  },
  not_found: {
    text: `<p>La page que vous recherchez ne semble pas disponible.</p>`,
    button: "Retourner à la page d'accueil",
  }
};
