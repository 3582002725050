export default {
  links: [
    // change to int
    {
      title: 'Mentions légales',
      link: 'Mentions-legales',
      type: 'internal'
    },
    {
      title: 'CGU',
      link: 'CGU',
      type: 'internal'
    },
    {
      title: 'Contact',
      link: 'mailto:info@ailoop.io',
      type: 'external'
    },
  ],
};
