import api from '../../utils/api';
import { isTokenValid } from '../../utils/authUtils';

export default {
  state: {
    userLoggedIn: !!localStorage.getItem('access'),
    user: {}
  },
  mutations: {
    setAuth(state, status) {
      state.userLoggedIn = status;
    },
  },
  actions: {
    async initLogin({ commit, dispatch }) {
      const token = localStorage.getItem('access');
      let isLoggedIn = isTokenValid(token);

      const refresh = localStorage.getItem('refresh');

      if (!isLoggedIn && refresh) {
        const status = await dispatch('refreshToken');
        isLoggedIn = status === 200;
      }

      if (!isLoggedIn) {
        dispatch('clearAuthData');
      }

      commit('setAuth', isLoggedIn);
      return isLoggedIn;
    },
    async refreshToken({ dispatch }) {
      const token = localStorage.getItem('refresh');

      if (!token) return;

      try {
        const { data, status } = await api.post('/tokens', {
          refresh: token,
        });

        if (status === 200) {
          localStorage.setItem('access', data.access);
        } else {
          dispatch('clearAuthData');
        }

        return status;
      } catch (err) {
        console.log(err);
        dispatch('clearAuthData');
      }
    },
    async login({ commit }, { email, password, remember_me }) {
      try {
        const { data, status } = await api.post('/sessions', {
          email,
          password,
          remember_me,
        });

        if (status === 200) {
          commit('setAuth', true);

          localStorage.setItem('access', data.access);
          localStorage.setItem('refresh', data.refresh);
          localStorage.setItem('user', JSON.stringify(data.user));
        }

        return status;
      } catch (err) {
        console.log(err);
      }
    },
    async logout({ commit, dispatch }) {
      const refreshToken = localStorage.getItem('refresh');
      const accessToken = localStorage.getItem('access');

      try {
        const { status } = await api.delete(`/sessions/${refreshToken}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (status === 204) {
          dispatch('clearAuthData');
          commit('setAuth', false);
        }

        return status;
      } catch (err) {
        console.log(err);
        dispatch('clearAuthData');
      }
    },
    getUser() {
      const fromStorage = localStorage.getItem('user');
      return JSON.parse(fromStorage);
    },
    clearAuthData({ commit }) {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      localStorage.removeItem('user');
      commit('setAuth', false);
    }
  },
};