const model = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  company: '',
  country: '',
  city: '',
  zip_code: '',
  address: ''
};

const form = [
  {
    type: 'text',
    name: 'first_name',
    label: 'Prénom',
    placeholder: 'Votre prénom',
    component: 'el-input',
    required: true,
    size: 'col-12 col-lg-6'
  },
  {
    type: 'text',
    name: 'last_name',
    label: 'Nom de famille',
    placeholder: 'Votre nom de famille',
    component: 'el-input',
    required: true,
    size: 'col-12 col-lg-6'
  },
  {
    type: 'text',
    name: 'phone',
    label: "Numéro de téléphone",
    placeholder: "Votre numéro de téléphone",
    component: 'el-input',
    required: true,
    size: 'col-12 col-lg-6'
  },
  {
    type: 'text',
    name: 'email',
    label: 'Adresse email',
    placeholder: 'Votre adresse email',
    component: 'el-input',
    required: true,
    size: 'col-12 col-lg-6',
    is_disabled: true
  },
  {
    type: 'text',
    name: 'company',
    label: 'Entreprise',
    placeholder: 'Votre entreprise',
    component: 'el-input',
    required: true,
    size: 'col-12 col-lg-6'
  },
  {
    type: 'text',
    name: 'country',
    label: 'Pays',
    placeholder: 'Votre pays',
    component: 'el-input',
    required: true,
    size: 'col-12 col-lg-6'
  },
  {
    type: 'text',
    name: 'city',
    label: "Ville",
    placeholder: "Votre ville",
    component: 'el-input',
    required: true,
    size: 'col-12 col-lg-6'
  },
  {
    type: 'text',
    name: 'zip_code',
    label: 'Code postal',
    placeholder: 'Votre code postal',
    component: 'el-input',
    required: true,
    size: 'col-12 col-lg-6'
  },
  {
    type: 'text',
    name: 'address',
    label: 'Adresse',
    placeholder: 'Votre adresse',
    component: 'el-input',
    required: true,
    is_password: false,
    size: 'col-12',
  },
];

const rules = {
  first_name: [
    {
      type: 'required',
      message: 'Veuillez entrer votre prénom',
    },
  ],
  last_name: [
    {
      type: 'required',
      message: 'Veuillez entre votre nom de famille',
    },
  ],
  phone: [
    {
      type: 'required',
      message: "Veuillez entrer votre nom d'utilisateur",
    },
    {
      type: 'phone',
      message: "Veuillez renseigner un numéro de téléphone valide."
    },
  ],
  email: [
    {
      type: 'required',
      message: 'Veuillez entrer votre adresse email',
    },
    {
      type: 'email',
      message: 'Veuillez entrer une adresse email valide',
    },
  ],
  company: [
    {
      type: 'required',
      message: 'Veuillez renseigner le nom de votre entreprise',
    },
  ],
  country: [
    {
      type: 'required',
      message: 'Veuillez renseigner le pays de votre entreprise',
    },
  ],
  city: [
    {
      type: 'required',
      message: "Veuillez renseignez la ville de votre entreprise",
    },
  ],
  zip_code: [
    {
      type: 'required',
      message: 'Veuillez renseigner le code postal de votre entreprise',
    },
    {
      type: 'zipcode',
      message: "Veuillez renseigner un code postal valide."
    },
  ],
  address: [
    {
      type: 'required',
      message: 'Veuillez renseigner l\'adresse de votre entreprise',
    },
  ],
};

export { model, form, rules };
