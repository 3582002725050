import axios from 'axios';

const api = axios.create({
  
  baseURL: process.env.VUE_APP_API_URL,

  // baseURL: 'http://localhost:3000/api',
  // baseURL: 'https://api-v1.epd-loop.org/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
