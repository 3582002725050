const model = {
  email: '',
  password: '',
};

const form = [
  {
    name: 'email',
    label: 'Adresse email',
    type: 'email',
    placeholder: 'Votre adresse email',
    required: true,
    component: 'el-input',
    size: 'col-12'
  },
  {
    name: 'password',
    label: 'Mot de passe',
    type: 'password',
    placeholder: 'Votre mot de passe',
    required: true,
    component: 'el-input',
    is_password: true,
    size: 'col-12'
  },

];

const rules = {
  email: [
    {
      type: 'required',
      message: 'Veuillez entrer votre adresse email.',
    },
    {
      type: 'email',
      message: 'Veuillez entrer une adresse email valide.',
    },
    {
      type: 'domain',
      message: 'Vous n\'avez pas les permissions requises pour vous connecter.'
    }
  ],
  password: [
    {
      type: 'minLength',
      minLength: 8,
      message: 'Votre mot de passe doit contenir au minimum 8 caractères.'
    },
    {
      type: 'required',
      message: 'Veuillez entrer votre mot de passe',
    },
  ],
};

export { model, form, rules };
