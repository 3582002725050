import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/config/utils/routes';
import store from '@/store';
import { isTokenValid } from '@/utils/authUtils';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state.auth.userLoggedIn;
  const token = localStorage.getItem('access');
  const tokenValid = isTokenValid(token);

  if (!tokenValid) {
    await store.dispatch('clearAuthData');
  }

  if (to.matched.some((record) => record.meta.requiresAuth === false) && isAuthenticated && tokenValid) {
    next({ name: 'Home' });
    return;
  }

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  if (isAuthenticated && tokenValid) {
    next();
  } else {
    next({ name: 'Home' });
  }
});

export default router;