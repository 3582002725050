<template>
  <div class="container privacy-container">
    <h1>This is the privacy page</h1>
  </div>
</template>

<script>
import useTexts from '@/mixins/useTexts';
export default {
  name: 'PrivacyPage',
  mixins: [useTexts],
  data() {
    return {
      page: 'privacy',
    };
  },
};
</script>
