const model = {
    calculation_name: '',
    project_name: '',
    product_model: '',
    material_name: '',
    attribute_1: '',
    attribute_2: '',
    density: '',
    is_galvanized: '',
    client_location: '',
};

const params = {
    // product_family: 'Sols industriels',
    // calculation_name: 'Nom du calcul',
    // project_name: 'Nom du projet',
    product_model: 'Type de sol industriel',
    material_name: 'Matière première principale',
    attribute_1: 'Largeur de la tôle (mm)',
    attribute_2: 'Epaisseur de la tôle (mm)',
    density: 'Densité (kg/m²)',
    // distance: 'Distance de transport',
    client_location: 'Code postal du chantier'
}

const form = [
    {
        type: 'text',
        name: 'calculation_name',
        label: 'Nom du calcul',
        placeholder: 'Choisissez le nom du calcul',
        component: 'el-input',
        required: true,
        is_request: false,
        is_disabled: false
    },
    {
        type: 'text',
        name: 'project_name',
        label: 'Nom du projet',
        placeholder: 'Choisissez le nom du projet',
        component: 'el-input',
        required: true,
        is_request: false,
        is_disabled: true
    },
    {
        type: 'text',
        name: 'product_model',
        label: 'Quel est le modèle de sol industriel ?',
        placeholder: 'Sélectionnez...',
        component: 'el-select',
        options: [],
        is_request: true,
        is_disabled: true
    },
    {
        type: 'text',
        name: 'material_name',
        label: 'Quel est la matière première ?',
        placeholder: 'Sélectionnez...',
        component: 'el-select',
        options: [],
        is_request: true,
        is_disabled: true
    },
    {
        type: 'text',
        name: 'attribute_1',
        label: 'Quelle est la largeur de la tôle ?',
        placeholder: 'Sélectionnez...',
        component: 'el-select',
        value: 'mm',
        options: [],
        is_request: true,
        is_disabled: true
    },
    {
        type: 'text',
        name: 'attribute_2',
        label: "Quelle est l'épaisseur de la tôle ?",
        placeholder: 'Sélectionnez...',
        component: 'el-select',
        value: 'mm',
        options: [],
        is_request: true,
        is_disabled: true
    },
    {
        type: 'text',
        name: 'density',
        label: 'Densité surfacique',
        placeholder: 'Sélectionnez...',
        component: 'el-input',
        value: 'kg/m²',
        options: [],
        is_request: true,
        is_disabled: true,
    },
    {
        type: 'text',
        name: 'is_galvanized',
        label: 'Le produit a-t-il été galvanisé à chaud ?',
        placeholder: 'Sélectionnez...',
        component: 'el-select',
        options: [
            {
                label: 'Oui',
                value: 1,
            },
            {
                label: 'Non',
                value: 0,
            },
        ],
        is_request: false,
        is_disabled: true
    },
    {
        type: 'text',
        name: 'client_location',
        label: 'Quel est le code postal du chantier ?',
        placeholder: 'Entrez le code postal',
        component: 'el-input',
        is_request: false,
        minLength: 5,
        maxLength: 5,
        is_disabled: true
    },
];

const rules = {
    calculation_name: [
        {
            type: 'required',
            message: 'Ce champs est requis.',
        },
    ],
    project_name: [
        {
            type: 'required',
            message: 'Ce champs est requis.',
        },
    ],
    product_model: [
        {
            type: 'required',
            message: 'Veuillez sélectionner un modèle.',
        },
    ],
    material_name: [
        {
            type: 'required',
            message: 'Ce champs est requis.',
        },
    ],
    attribute_1: [
        {
            type: 'required',
            message: 'Ce champs est requis.',
        },
    ],
    attribute_2: [
        {
            type: 'required',
            message: 'Ce champs est requis.',
        },
    ],
    density: [],
    is_galvanized: [
        {
            type: 'required',
            message: 'Ce champs est requis.',
        },
    ],
    client_location: [
        {
            type: 'required',
            message: 'Veuillez fournir votre code postal.',
        },
        {
            type: 'zipcode',
            message: 'Veuillez renseigner un code postal valide.'
        },
        {
            type: 'minLength',
            minLength: 5,
            message: 'Votre code postal ne peut contenir que 5 caractères'
        },
        {
            type: 'minLength',
            maxLength: 5,
            message: 'Votre code postal ne peut contenir que 5 caractères'
        },
    ],
};

export {model, form, rules, params};
